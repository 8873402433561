<template>
    <div id="fb-root"></div>
    <div class="container">
       <!-- <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/coloradojackorders.webp" class="background-pic" />-->
        <div class="res-card center shadow-card mt-5 pt-5">
            <el-alert v-show="errorMessage&&errorMessage.length>0" :title="errorMessage"
                      type="error"
                      effect="dark">
            </el-alert>

            
            <el-form :model="login" :rules="loginRules" ref="loginForm" :label-position="top" hide-required-asterisk="true" show-message="false" v-loading="loading">
                <section class="pb-4">
                    <h1 class="text-center font-weight-bold" style="color: #983222">SIGN IN</h1>
                </section>
                <div class="row mt-1 mb-3">
                    <div class="col-6 text-center">
                        <a class="facebook-button" @click="facebookSignin"><i class="fab fa-facebook-square mr-2"></i> Continue with Facebook</a>
                    </div>
                    <div class="col-6 text-center">
                        <a id="google-button" class="google-button"><i class="fab fa-google mr-2"></i> Continue with Google</a>
                    </div>
                </div>

                <!--<el-form :model="login" ref="loginForm" :label-position="top" hide-required-asterisk="true" show-message="false">-->
                <div class="word-line-break mt-3"><span>OR</span></div>
                <el-form-item label="EMAIL" prop="email">
                    <el-input type="email" v-model="login.email" autocomplete="off" v-on:keyup.enter="submitLogin" v-focus v-fancylabel></el-input>
                </el-form-item>
                <el-form-item label="Password" prop="password">
                    <el-input type="password" v-model="login.password" v-on:keyup.enter="submitLogin" v-fancylabel></el-input>

                </el-form-item>
                <div class="row col-md-6 center mt-0 mb-3" style="justify-content:center">
                    <router-link style="padding-top:0px; padding-bottom:10px; margin-left:10px; " :to="{ name: 'ForgotPassword' }" class="">Forgot My Password</router-link>
                </div>
                

                <div class="text-center">
                    <el-button type="primary" @click="submitLogin" :loading="loading" style="width: 100%; background-color: #983222">LOGIN</el-button>
                    <div class="word-line-break mt-3 "><span>OR</span></div>
                    <el-button type="success" @click="signup" :loading="loading" style="width:100%; padding-bottom:20px">START YOUR ONLINE FUNDRAISER</el-button>
                </div>
            </el-form>
            <div class="text-center mt-3">
               <!-- <el-button type="text" @click="termsDialogVisible = true">Terms and Conditions</el-button>-->
                <router-link style="padding-top:0px; padding-bottom:10px;" :to="{ name: 'TermsOfUse' }" class="">Terms and Conditions</router-link>
                <!--<el-dialog v-model="termsDialogVisible" title="Terms and Conditions" :width="dialogWidth" fullscreen="true" center>-->

                    <!-- <embed src="https://coloradojackgivebacksa.blob.core.windows.net/termsandconditions/ColoradoJackGiveBack.TERMS.pdf#toolbar=0&navpanes=0&scrollbar=0" type="application/pdf" width="100%" height="900px" />-->
                    <!--<div class="iframe-container">
                        <iframe src="https://coloradojackgivebacksa.blob.core.windows.net/termsandconditions/ColoradoJackGiveBack.TERMS.pdf#toolbar=0&navpanes=0&scrollbar=0"></iframe>
                    </div>-->
                <!--</el-dialog>-->
                <!--<router-link style="padding-top:0px; padding-bottom:10px;" :to="{ name: 'TermsOfUse' }" class="nav-link">By signing up you accept our terms of use</router-link>-->
            </div>
        </div>
    </div>
</template>

<script>

    import store from "@/store"
    import axios from "axios"
    import { initFacebookSdk, } from '@/services';
    import router from "../router";

    //function signOut() {
    //    var auth2 = window.gapi.auth2.getAuthInstance();
    //    auth2.signOut().then(function () {
    //        console.log("User signed out.");
    //    });
    //}

    export default {
        name: "Login",
        data() {
            return {
                termsDialogVisible:false,
                errorMessage:'',
                login: {
                    email: '',
                    password: ''
                },
                loginRules: {
                    email: [
                        { required: true, type: 'email', message: 'Invalid email address', trigger: 'change' }
                    ],
                    password: [
                        { required: true, regexp: '^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z[0-9]]{8,}$', message: 'Password is required', trigger: 'blur' }
                    ]
                },
                loading: false
            }
        },
        methods: {
            submitLogin() {
                this.$refs['loginForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        const loginUrl = "/api/users/authenticate";
                        axios.post(loginUrl, this.login).then(response => {
                            if (response.status === 200) {
                                store.dispatch("login", response);
                            }
                            else
                                this.errorMessage = response.message;
                        }).catch((error) => {
                            this.loading = false;
                            if (error && error.response && error.response.data)
                                this.errorMessage = error.response.data.message;
                        });   
                    }
                });
            },
            googleSignin(googleUser) {               
                this.loading = true;
                // Useful data for your client-side scripts:
                var profile = googleUser.getBasicProfile();
                let user = {
                    idToken: googleUser.getAuthResponse().id_token,
                    email: profile.getEmail(),
                    fullName: profile.getName(),
                    givenName: profile.getGivenName(),
                    familyName: profile.getFamilyName(),
                    imageSrc: profile.getImageUrl()

                };
                                
                axios.post("/api/users/google-authenticate", user).then(response => {
                    if (response.status === 200) {
                        store.dispatch("login", response);
                    }
                    else
                        this.errorMessage = response.message;
                }).catch((error) => {
                    this.loading = false;
                    if (error && error.response && error.response.data)
                        this.errorMessage = error.response.data.message;
                });
            },
            facebookSignin() {
                this.loading = true;
                // login with facebook then authenticate with the API to get a JWT auth token
              
                window.FB.login(async ({ authResponse }) => {
                    const serverresponse = await axios.post("/api/users/facebook-authenticate", { accessToken: authResponse.accessToken });

                    this.loading = false;
                    store.dispatch("login", serverresponse);

                }, { scope: 'email' });
                             


                //const { authResponse } = await window.FB.login({ scope: 'email' });
                //if (!authResponse) {
                //    this.loading = false;
                //    return;
                //}
                //else {
                //    // authenticate with the api using a facebook access token,
                //    // on success the api returns an account object with a JWT auth token
                //    const response = await axios.post("/api/users/facebook-authenticate", { accessToken: authResponse.accessToken });
                //    this.loading = false;
                //    store.dispatch("login", response);
                //}



                //const { authResponse } = await new Promise(window.FB.login);
                //if (!authResponse) return;
                 
                //// authenticate with the api using a facebook access token,
                //// on success the api returns an account object with a JWT auth token
                //const response = await axios.post("/api/users/facebook-authenticate", { accessToken: authResponse.accessToken });
                //this.loading = false;
                //store.dispatch("login", response);
            },
            signup() {
                router.push("/Signup");
            }
        },
        created() {          
            initFacebookSdk();
            if (store.isAuthenticated) {
                store.dispatch("routeUser");
            }            
        },
        computed: {
            dialogWidth() {
                if (screen.width <= 576)
                    return "100%";
                else return "500px";
            },
        },
        mounted() {
            let $this = this;
            //init google login integration
            //first get google client id
            axios.get("/api/Common/GoogleClientId").then(response => {
                let wt = window.setInterval(function () {
                    if (window.gapi == null)
                        return;
                    clearInterval(wt);
                    window.gapi.load('auth2', function () {
                        // Retrieve the singleton for the GoogleAuth library and set up the client.
                        let auth2 = window.gapi.auth2.init({
                            client_id: response.data
                        });

                        auth2.attachClickHandler(document.getElementById('google-button'), {},
                            function (googleUser) {
                                $this.googleSignin(googleUser);
                            }, function (error) {

                            });
                    });
                }, 100);
            });
            //init facebook login integration
           
        }
    }
</script>

<style scoped>

    .facebook-button {
        background-color: #3b579d;
        border: 0;
        border-radius: 3px;
        padding: 15px 20px;
        color: white;
        font-size: 1rem;
        width:100%;
        text-decoration:none;
        display:block;
    }

    .google-button {
        background-color: #DB4437;
        border: 0;
        border-radius: 3px;
        padding: 15px 20px;
        color: white;
        font-size: 1rem;
        width: 100%;
        text-decoration: none;
        display: block;
    }

    button.facebook-button i, button.google-button i {
        font-size: 1.3rem;
    }

    .res-card {
        background-color: white;
    }

    .iframe-container {
        overflow: hidden;
        /* 16:9 aspect ratio */
        padding-top: 100%;
        position: relative;
    }

    .iframe-container iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    
        
</style>