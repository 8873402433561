<template>
    <div class="center mb-5" style="background-color: white;">
        <div class="row center col-md-12 justify-content-center mb-1" style="padding-right:unset; padding-left:unset">
            <!-- <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/popcorn3200x800.webp" class="col-md-12" style="aspect-ratio:16/4; padding-left:0; padding-right:0" />-->
            <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/popcorn3200x800.webp" class="col-md-12 img-fluid" style="padding-left:0; padding-right:0" />
        </div>
        <div class="row center col-md-11 mb-1 mt-2" style="text-align: center; justify-content: center">
            <!--<h1 class="col-10" style="text-shadow: 2px 2px #808080; ">LEGENDARY POPCORN. LEGENDARY FUNDRAISER.</h1>-->
            <h1 class="col-10" style="">LEGENDARY POPCORN. LEGENDARY FUNDRAISER.</h1>
        </div>
        <div class="row center col-md-11 mb-1" style="text-align: center; justify-content: center">
            <h6 class="col-12 font-weight-bold">Everyone loves popcorn which makes it easy to sell and a quick way to reach your fundraising goals.</h6>
        </div>
        <div class="row center col-md-11 mb-5" style="text-align: center; justify-content: center;">
            <h6 class="col-12 font-weight-bold" style="color: #983222">Earn 50% on every bag sold, and no fees or minimums required for using our online platform!</h6>
        </div>

        <div class="container-fluid row col-md-11 center mb-3" style="text-align:center; justify-content:center">
            <div class="col-md-6 mb-3" style="text-align:center">
                <div class="row col-10 center">
                    <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/brochure.webp" class="img-fluid center" />
                </div>
                <div class="row col-10 center">
                    <H1 class="mt-3 center">CLASSIC FUNDRAISER</H1>
                </div>
                <div class="row col-10 center">
                    <H6 class="mt-1 center mb-2 ">Flexible options, easy ordering and FREE shipping!</H6>
                </div>
                <div class="row col-12 center" style="justify-content:center">
                    <el-button type="danger" style="background-color: #983222; color: white; width: 60%; padding-bottom: 20px; justify-content:center" size="large" @click="toBrochure">GET STARTED</el-button>
                </div>
            </div>

            <div class="col-md-6 mb-3" style="text-align:center">
                <div class="row col-10 center" style="height:144px">
                    <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/virtualfundraiser.webp" class="img-fluid center" />
                </div>
                <div class="row col-10 center">
                    <H1 class="mt-3 center">ONLINE FUNDRAISER</H1>
                </div>
                <div class="row col-12 center">
                    <H6 class="mt-1 center mb-2">Raise funds quickly and easily through an online store!</H6>
                </div>
                <div class="row col-12 center" style="justify-content:center">
                    <el-button type="danger" style="background-color: #983222; color: white; width: 60%; padding-bottom: 20px" size="large" @click="toNewEvent">GET STARTED</el-button>
                </div>
            </div>
        </div>

        <!--<div class="row justify-content-end">
        <div class="col-md-4" style="justify-content:end">
            <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/popcornDrawing.png" class="img-fluid" />
        </div>
    </div>-->
        <div class="row justify-content-center">
            <div class="col-md-11">
                <H1 class="mt-5" style="color: #983222">COLORADO JACK GIVEBACK</H1>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-10 mt-1 mb-5">
                <H6 class="mt-3" style="">Colorado Jack Giveback is the ultimate Fundraiser. With the option to do either Classic or Online, your organization will not only see a generous return but will also be creating meaningful work & sustainable jobs...</H6>
                <H6 class="mt-3 font-italic">
                    "Sometimes you can't pay it back, so you just have to pay it forward"
                    - Randy Pausch
                </H6>
            </div>
        </div>
        <div class="row center col-md-11 justify-content-center ">
            <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/BagsWood.webp" class="img-fluid col-md-11" />
        </div>
        <!--<div class="row justify-content-center">
        <div class="col-md-11">
            <H1 class="mt-3" style="">FUNDRAISING NEVER TASTED SO GOOD!</H1>
        </div>
    </div>-->
        <div class="row justify-content-center">
            <div class="col-md-8">
                <H6 class="mt-3" style="">Everyone loves popcorn. With our special blend of toppings, it's the perfect snack. With one taste, your family and friends will be asking for more!</H6>
            </div>
        </div>
        <!--<div class="row justify-content-center mb-5 mt-5" style="text-align:center">
        <el-button type="danger" style="background-color: #983222; color: white; width: 30%; padding-bottom: 20px" size="large" @click="toNewEvent">GET STARTED</el-button>
    </div>-->
        <div class="row center col-md-11 justify-content-center mt-5 mb-5">
            <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/flavors.webp" class="img-fluid col-md-11" />
        </div>

        <!--<div class="row center col-md-11 justify-content-center mt-5 mb-5">
        <div class="col-md-5">
            <H1 class="mb-2">WHY VIRTUAL FUNDRAISING?</H1>
            <p class="mt-2 ml-5">1. The product will be shipped directly to the donator, so there is no product to distribute and no payments to collect.</p>
            <p class="mt-2 ml-5">2. The donator will pay for their order on the eCommerce virtual store.</p>
            <p class="mt-2 ml-5">3. Invites are sent directly from the computer or mobile device to donators.</p>
            <p class="mt-2 ml-5" style="color: #983222">4. Keep 50% of what you sell.</p>
            <p class="mt-2 ml-5">Are you ready to start a new Virtual Fundraiser?</p>
        </div>
        <div class="col-md-5 ">
            <div class="row justify-content-center mt-3 ">
                <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/caramel.webp" class="img-fluid mb-3" />
                <el-button type="danger" style="background-color: #983222; color:white; width: 60%; padding-bottom: 20px" size="large" @click="toNewEvent">Start Today!</el-button>
            </div>
        </div>
    </div>-->
        <!--<hr class="col-md-12 mb-5" style="color: #983222; height: 20px; background-color: #983222;" />

    <div class="row center col-md-11 justify-content-center mt-lg-5 mb-5">
        <div class="col-md-5">
            <H1 class="center" style="color: #983222">SAY HELLO</H1>
            <p class="mt-3 font-weight-bold" style="color: #983222;">EMAIL ADDRESS</p>
            <p class="mt-2"><a href="mailto:info@coloradojackgiveback.com">info@coloradojackgiveback.com</a></p>
            <p class="mt-2 font-weight-bold" style="color: #983222">MAILING ADDRESS</p>
            <p class="mt-2">1702 Schwan Ave NE, Devils Lake, ND 58301</p>
            <p class="mt-2 font-weight-bold" style="color: #983222">PHONE NUMBER</p>
            <p class="mt-2 mb-5">701-456-7890</p>
        </div>
        <div class="col-md-5 ">
            <div class="row justify-content-center mt-3 ">
                <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/normalPopcorn.webp" class="img-fluid mb-3" />
            </div>
        </div>
    </div>-->

    </div>
</template>

<script>
    import router from "../router";
    import { mapGetters } from "vuex"

export default {
        name: 'Home',
        methods: {
            toNewEvent() {
                router.push("/VirtualFundraising");
            },
            toLogin() {
                router.push("/login");
            },
            toBrochure() {
                router.push("/BrochureForm");
            }
        },
        computed: {
            ...mapGetters([
                'isAuthenticated', 'cart', 'currentUserDetail'
            ])
        },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .content {
        padding: 0% 0;
    }

    /* page content */
    h1, h2, h3, h4, h5 {
        /*font-family: Trebuchet MS;*/
        font-weight: bold;
        text-align: center;
    }
    h6 {
        /*font-family: Trebuchet MS;*/
        text-align: center;
    }


    .res-card {
        background-color: white;
    }     

    .multi-bg-example {
        width: 100%;
        height: 800px;
        background-image: url("https://coloradojackgivebacksa.blob.core.windows.net/publicwebsiteimages/5 Flavors with corn cobs.png");
        background-repeat: no-repeat;
        background-position: bottom right, left, right;
    }

    .jumbotron-welcome {
        background-image: url("https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/Kid.jpg");
        background-size: cover;
        color: white;
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 380px) {
        p {
            font-size: 1.0rem;
        }
        h1 {
            font-size: 1.5rem;
        }
        h2 {
            font-size: 1.0rem;
        }
        h5 {
            font-size: 1.0rem;
        }
        h6 {
            font-size: 1.0rem;
        }
        /*1rem = 16px*/
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 544px) {
        p {
            font-size: 1.0rem;
        }
        h1 {
            font-size: 1.5rem;
        }
        h2 {
            font-size: 1.25rem;
        }
        h5 {
            font-size: 1.00rem;
        }
        h6 {
            font-size: 1.0rem;
        }
        /*1rem = 16px*/
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {
        p {
            font-size: 1.0rem;
        }
        h1 {
            font-size: 2.0rem;
        }
        h2 {
            font-size: 1.75rem;
        }
        h5 {
            font-size: 1.25rem;
        }
        h6 {
            font-size: 1.0rem;
        }
        /*1rem = 16px*/
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
        p {
            font-size: 1.25rem;
        }
        h1 {
            font-size: 2.5rem;
        }
        h2 {
            font-size: 2.0rem;
        }
        h5 {
            font-size: 1.5rem;
        }
        h6 {
            font-size: 1.15rem;
        }
        /*1rem = 16px*/
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        p {
            font-size: 1.50rem;
        }
        h1 {
            font-size: 3.0rem;
        }
        h2 {
            font-size: 2.5rem;
        }
        h5 {
            font-size: 1.75rem;
        }
        h6 {
            font-size: 1.20rem;
        }
        /*1rem = 16px*/
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
    }

    .video-container iframe, .video-container object, .video-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
  

</style>
