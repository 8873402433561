<template>
    <div id="fb-root"></div>
    <div class="container">
       <!-- <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/coloradojackorders.webp" class="background-pic" />-->
        <div class="res-card center shadow-card mt-5 pt-5">
            <el-alert v-show="errorMessage&&errorMessage.length>0" :title="errorMessage"
                      type="error"
                      effect="dark">
            </el-alert>

            <transition name="el-fade-in-linear" v-if="currentStep==1">
                <el-form :model="login" :rules="loginRules" ref="loginForm" :label-position="top" hide-required-asterisk="true" show-message="false" v-loading="loading">
                    <section class="pb-4">
                        <h3 class="text-center font-weight-bold" style="">Sign In</h3>                        
                    </section>
                    <section class="pb-4">
                        <h5 class="text-center font-weight-bold" style="color: #983222">Please enter your mobile number</h5>
                        <h6 class="text-center" style="color: #983222">We will send an sms text to your phone number.</h6>
                    </section>

                    <el-form-item label="Enter your 10 digit Mobile: 555-555-5555" prop="phone">
                        <!--<el-input type="text" v-model="login.formattedPhone" autocomplete="off" v-on:keyup.enter="focusOut" @blur="focusOut" v-focus v-fancylabel />-->
                        <el-input type="text" v-model="login.formattedPhone" autocomplete="off" v-on:keyup.enter="focusOut" @blur="focusOut" v-focus v-fancylabel />                        
                        <h6 class="text-center" style="color: #983222; font-size:xx-small">Normal message and data rate may apply.</h6>
                    </el-form-item>

                    <div class="text-center">
                        <el-button type="primary" @click="confirmPhone" :loading="loading" style="width: 100%; background-color: #983222">Send verification code</el-button>
                    </div>
                </el-form>
            </transition>

            <transition name="el-fade-in-linear" v-if="currentStep==2">
                <div class="widget step">
                    <el-form :model="login" :rules="step2Rules" ref="step2Form" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent v-loading="loading">
                        <section class="form-title">
                            <h5 style="font-weight:500">We have sent a code to you mobile phone: {{this.login.formattedPhone}}, please enter that code here:</h5>
                        </section>
                        <el-form-item>
                            <el-input type="text" style="width: 100%;" v-model="login.code" maxlength="6" show-word-limit class="email-code" autocomplete="off" v-focus v-fancylabel @blur="confirmPhoneCode" v-on:keyup.enter="confirmPhoneCode"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button style="background-color: #983222" type="primary" @click="currentStep--" class="float-left">BACK</el-button>
                            <el-button type="danger" style="background-color: #983222" @click="confirmPhoneCode" :loading="loading" class="float-left">SUBMIT</el-button>
                            <!--<el-button type="primary" plain @click="resendConfirmPhoneCode" :loading="loading" class="float-left">RESEND</el-button>-->
                        </el-form-item>
                    </el-form>
                </div>
            </transition>

            <transition name="el-fade-in-linear" v-if="currentStep==3">
                <div class="widget step">
                    <el-form :model="login" :rules="step3Rules" ref="step3Form" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent v-loading="loading">
                        <section class="form-title" style="text-align:left">
                            <h4 style="font-weight:500" class="">We just need a little more information to setup you new user account.</h4>
                        </section>
                        <el-form-item label="EMAIL" prop="email">
                            <el-input type="email" v-model="login.email" autocomplete="off" v-fancylabel></el-input>
                        </el-form-item>
                        <el-form-item label="FIRST NAME" prop="firstName">
                            <el-input type="text" v-model="login.firstName" autocomplete="off" v-focus v-fancylabel v-on:keyup.enter="confirmEmail"></el-input>
                        </el-form-item>
                        <el-form-item label="LAST NAME" prop="lastName">
                            <el-input type="text" v-model="login.lastName" autocomplete="off" v-fancylabel v-on:keyup.enter="confirmEmail"></el-input>
                        </el-form-item>
                        <el-form-item>
                           
                        </el-form-item>
                        <div class="text-center">
                            <router-link class="text-center" style="width: 100%; font-size: small" :to="{ name: 'TermsOfUse' }">By registering you agree to our Terms and Conditions</router-link>
                            <el-button type="primary" style="width: 100%; background-color: #983222" @click="register" :loading="loading" class="text-center">Register</el-button>
                        </div>
                    </el-form>
                   
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    
    import store from "@/store"
    import axios from "axios"
    import { initFacebookSdk, } from '@/services';
    import router from "../router";   

    export default {
        name: "Login",
        data() {
            return {
                termsDialogVisible:false,
                errorMessage: '',
                currentStep: 1,  
                preventNextIteration: false,
                login: {
                    phone: "0",    
                    formattedPhone: "",
                    code: '',
                    verified: false,
                    email: '',
                    firstName: '',
                    lastName: ''
                },
                mobileAuth: {
                    statusmessage: '',
                    userexists: false,
                },
                loginRules: {
                    phone: [
                        { required: true, type: 'string', pattern: /^\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{4}$/g, message: 'A valid Phone Number is required', trigger: 'blur' }
                    ]                  
                },
                step3Rules: {
                    email: [
                        { required: true, type: 'email', message: 'Invalid email address', trigger: 'blur' }
                    ],
                    firstName: [
                        { required: true, message: 'First Name is required', trigger: 'blur' }
                    ],
                    lastName: [
                        { required: true, message: 'Last Name is required', trigger: 'blur' }
                    ],
                },
                loading: false
            }
        },
        methods: {
            focusOut: function (event) {
                if (['Arrow', 'Backspace', 'Shift'].includes(event.key)) {
                    this.preventNextIteration = true;
                    return;
                }
                if (this.preventNextIteration) {
                    this.preventNextIteration = false;
                    return;
                }
                this.login.phone = this.login.formattedPhone.replace(/-/g, '').match(/(\d{1,10})/g)[0];

                // Format display value based on calculated currencyValue
                this.login.formattedPhone = this.login.phone.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, '$1-$2-$3');
                this.confirmPhone();
            },

            next(stepForm) {
                this.$refs[stepForm].validate((valid) => {
                    if (valid)
                        this.currentStep++;
                });
            },
            prev() {
                this.currentStep--;
            },
            submitLogin() {
                this.$refs['loginForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        const loginUrl = "/api/users/sendverifymobilecode";
                        axios.post(loginUrl, this.login).then(response => {
                            if (response.status === 200) {
                                store.dispatch("login", response);
                            }
                            else
                                this.errorMessage = response.message;
                        }).catch((error) => {
                            this.loading = false;
                            if (error && error.response && error.response.data)
                                this.errorMessage = error.response.data.message;
                        });   
                    }
                });
            },
            confirmPhone() {
                if (this.validateForm('loginForm') === true) {
                    this.loading = true;
                    axios.post('/api/users/sendverifymobilecode', { phone: this.login.formattedPhone }).then(response => {
                        this.loading = false;
                        this.currentStep++;
                    }).catch((error) => {
                        this.errorMessage = error.response.data;
                        this.loading = false;                        
                    });
                }
            },
            async confirmPhoneCode() {
                if ((this.login.code != '') && (this.login.code.length == 6)) {
                    this.loading = true;
                    this.errorMessage = '';
                    await axios.post('/api/users/verifymobilecode', { phone: this.login.formattedPhone, code: this.login.code }).then(response => {
                        this.mobileAuth.statusmessage = response.data.statusMessage;
                        this.mobileAuth.userexists = response.data.userExists;
                        if (this.mobileAuth.statusmessage == "approved") {
                            if (this.mobileAuth.userexists) {
                                const loginUrl = "/api/users/authenticate";
                                axios.post(loginUrl, { mobile: this.login.formattedPhone }).then(response => {
                                    if (response.status === 200) {
                                        store.dispatch("login", response);
                                        if (this.$route.params.signup == 1) {
                                            router.push('/NewEvent');
                                        }
                                        else if (this.$route.params.signup == 2) {
                                            router.push('/dashboard/options');
                                        }
                                    }
                                    else
                                        this.errorMessage = response.message;
                                }).catch((error) => {
                                    this.loading = false;
                                    if (error && error.response && error.response.data)
                                        this.errorMessage = error.response.data.message;
                                });
                            }
                            else {
                                //register user
                                this.loading = false;
                                this.currentStep++;
                            }
                        }
                        else {
                            this.errorMessage = this.mobileAuth.statusmessage;
                        }
                        this.loading = false;

                    }).catch((error) => {
                        this.errorMessage = error.response.data;
                        this.loading = false;
                    });
                }               
            },
            register() {
                this.$refs['step3Form'].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        axios.post('/api/users/signupuser', { phone: this.login.formattedPhone, code: this.login.code, email: this.login.email, firstName: this.login.firstName, lastName: this.login.lastName }).then(response => {
                            this.loading = false;
                            if (response.status === 200) {
                                this.$message({
                                    message: 'Successfully created user account.',
                                    type: 'success'
                                });
                                const loginUrl = "/api/users/authenticate";
                                axios.post(loginUrl, { mobile: this.login.formattedPhone }).then(response => {
                                    if (response.status === 200) {
                                        store.dispatch("login", response);
                                        if (this.$route.params.signup == 1) {
                                            router.push('/NewEvent');
                                        }
                                        else if (this.$route.params.signup == 2) {
                                            router.push('/dashboard/options');
                                        }
                                    }
                                    else
                                        this.errorMessage = response.message;
                                }).catch((error) => {
                                    this.loading = false;
                                    if (error && error.response && error.response.data)
                                        this.errorMessage = error.response.data.message;
                                });
                            }
                        }).catch((error) => {
                            this.errorMessage = error.response.data;
                            this.loading = false;
                        });
                    }
                });
            },
            async resendConfirmPhoneCode() {
                this.loading = true;
                await axios.post('/api/users/sendverifymobilecode', { phone: this.login.formattedPhone }).then(response => {
                    this.loading = false;
                    if (response.status === 200) {
                        this.$message({
                            message: 'SMS login code resent.',
                            type: 'success'
                        });
                    }
                }).catch((error) => {
                    this.errorMessage = error.response.data;
                    this.loading = false;
                });
            },
            validateForm(form) {
                let isValid = false;
                this.$refs[form].validate((valid) => {
                    isValid = valid;
                });
                return isValid;
            },
            googleSignin(googleUser) {               
                this.loading = true;
                // Useful data for your client-side scripts:
                var profile = googleUser.getBasicProfile();
                let user = {
                    idToken: googleUser.getAuthResponse().id_token,
                    email: profile.getEmail(),
                    fullName: profile.getName(),
                    givenName: profile.getGivenName(),
                    familyName: profile.getFamilyName(),
                    imageSrc: profile.getImageUrl()

                };
                                
                axios.post("/api/users/google-authenticate", user).then(response => {
                    if (response.status === 200) {
                        store.dispatch("login", response);
                    }
                    else
                        this.errorMessage = response.message;
                }).catch((error) => {
                    this.loading = false;
                    if (error && error.response && error.response.data)
                        this.errorMessage = error.response.data.message;
                });
            },
            facebookSignin() {
                this.loading = true;
                // login with facebook then authenticate with the API to get a JWT auth token
              
                window.FB.login(async ({ authResponse }) => {
                    const serverresponse = await axios.post("/api/users/facebook-authenticate", { accessToken: authResponse.accessToken });

                    this.loading = false;
                    store.dispatch("login", serverresponse);

                }, { scope: 'email' });
            },
            signup() {
                router.push("/Signup");
            }
        },
        created() {          
            initFacebookSdk();
            if (store.isAuthenticated) {
                store.dispatch("routeUser");
            }            
        },
        computed: {
            dialogWidth() {
                if (screen.width <= 576)
                    return "100%";
                else return "500px";
            },
        },
        mounted() {
            let $this = this;
            //init google login integration
            //first get google client id
            axios.get("/api/Common/GoogleClientId").then(response => {
                let wt = window.setInterval(function () {
                    if (window.gapi == null)
                        return;
                    clearInterval(wt);
                    window.gapi.load('auth2', function () {
                        // Retrieve the singleton for the GoogleAuth library and set up the client.
                        let auth2 = window.gapi.auth2.init({
                            client_id: response.data
                        });

                        auth2.attachClickHandler(document.getElementById('google-button'), {},
                            function (googleUser) {
                                $this.googleSignin(googleUser);
                            }, function (error) {

                            });
                    });
                }, 100);
            });
            //init facebook login integration
           
        }
    }
</script>

<style scoped>
    .email-code {
        text-align: center;
        font-size: 20px;
        width: 45%;
    }
    .facebook-button {
        background-color: #3b579d;
        border: 0;
        border-radius: 3px;
        padding: 15px 20px;
        color: white;
        font-size: 1rem;
        width:100%;
        text-decoration:none;
        display:block;
    }

    .google-button {
        background-color: #DB4437;
        border: 0;
        border-radius: 3px;
        padding: 15px 20px;
        color: white;
        font-size: 1rem;
        width: 100%;
        text-decoration: none;
        display: block;
    }

    button.facebook-button i, button.google-button i {
        font-size: 1.3rem;
    }

    .res-card {
        background-color: white;
    }

    .iframe-container {
        overflow: hidden;
        /* 16:9 aspect ratio */
        padding-top: 100%;
        position: relative;
    }

    .iframe-container iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    
        
</style>