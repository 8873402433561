<template>
    <!--<div class="row center col-md-12 justify-content-center mb-1" style="padding-right:unset; padding-left:unset">
        <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/BagsWood.webp" class="col-md-12 img-fluid" style="aspect-ratio:16/5; padding-left:0; padding-right:0" />
    </div>-->
   
    <div class="container-fluid center mb-5" style="background-color: white;">
        <div class="row justify-content-center">
            <div class="col-md-11">
                <H1 class="mt-3" style="color: #983222">Getting Started</H1>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-md-6 mb-2">
                <H6 class="mt-3" style="">Creating your first Online Fundraising Event!</H6>
            </div>
        </div>

        <div class="row justify-content-center mb-2">
            <div style="position:relative;width:fit-content;height:fit-content;">
                <!--<video src="https://coloradojackgivebacksa.blob.core.windows.net/help/CreatingOnlineFundraiserColoradoJackGiveback.mp4" width="640" height="360" controls="controls" preload="auto" >Getting Started</video>-->
                <iframe src="https://www.youtube.com/embed/6YC8gN5Nl8w" title="Colorado Jack Giveback Fundraiser Creation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-md-6 mb-2">
                <H6 class="mt-3" style="">Inviting Team members to be Sellers in your fundraising Event!</H6>
            </div>
        </div>
        <div class="row justify-content-center mb-2">
            <div style="position:relative;width:fit-content;height:fit-content;">
                <!--<video src="https://coloradojackgivebacksa.blob.core.windows.net/help/CreatingOnlineFundraiserColoradoJackGiveback.mp4" width="640" height="360" controls="controls" preload="auto" >Getting Started</video>-->
                <iframe  src="https://www.youtube.com/embed/FBJeiKRtvZs" title="Colorado Jack Giveback Seller signup" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</template>

<script>
    import router from "../router";
    import { mapGetters } from "vuex"

export default {
        name: 'Help',
        methods: {
            toNewEvent() {
                router.push("/Signup");
            },
            toLogin() {
                router.push("/login");
            }
        },
        computed: {
            ...mapGetters([
                'isAuthenticated', 'cart', 'currentUserDetail'
            ])
        },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    /* page content */
    h1, h2, h3, h4, h5 {
        /*font-family: Trebuchet MS;*/
        font-weight: bold;
        text-align: center;
    }
    h6 {
        /*font-family: Trebuchet MS;*/
        text-align: center;
    }


    .res-card {
        background-color: white;
    }

    .multi-bg-example {
        width: 100%;
        height: 800px;
        background-image: url("https://coloradojackgivebacksa.blob.core.windows.net/publicwebsiteimages/5 Flavors with corn cobs.png");
        background-repeat: no-repeat;
        background-position: bottom right, left, right;
    }

    .jumbotron-welcome {
        background-image: url("https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/Kid.jpg");
        background-size: cover;
        color: white;
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 380px) {
        p {
            font-size: 1.0rem;
        }
        h1 {
            font-size: 1.5rem;
        }
        h2 {
            font-size: 1.0rem;
        }
        h5 {
            font-size: 1.0rem;
        }
        h6 {
            font-size: 1.0rem;
        }
        iframe {
            width: 350px;
            height: 300px;
        }
        /*1rem = 16px*/
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 544px) {
        p {
            font-size: 1.0rem;
        }
        h1 {
            font-size: 1.5rem;
        }
        h2 {
            font-size: 1.25rem;
        }
        h5 {
            font-size: 1.00rem;
        }
        h6 {
            font-size: 1.0rem;
        }
        iframe {
            width: 350px;
            height: 300px;
        }
        /*1rem = 16px*/
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {
        p {
            font-size: 1.0rem;
        }
        h1 {
            font-size: 2.0rem;
        }
        h2 {
            font-size: 1.75rem;
        }
        h5 {
            font-size: 1.25rem;
        }
        h6 {
            font-size: 1.0rem;
        }
        iframe {
            width: 560px;
            height: 315px;
        }
        /*1rem = 16px*/
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
        p {
            font-size: 1.25rem;
        }
        h1 {
            font-size: 2.5rem;
        }
        h2 {
            font-size: 2.0rem;
        }
        h5 {
            font-size: 1.5rem;
        }
        h6 {
            font-size: 1.25rem;
        }
        iframe {
            width: 560px;
            height: 315px;
        }
        /*1rem = 16px*/
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        p {
            font-size: 1.50rem;
        }
        h1 {
            font-size: 3.0rem;
        }
        h2 {
            font-size: 2.5rem;
        }
        h5 {
            font-size: 2.0rem;
        }
        h6 {
            font-size: 1.60rem;
        }
        iframe {
            width: 560px;
            height: 315px;
        }
        /*1rem = 16px*/
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
    }

    .video-container iframe, .video-container object, .video-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
  

</style>
