<template>
    <div class="image-container pt-35 mt-lg-5 mb-2" v-if="imageSrc">
        <vue-cropper ref="cropper" 
                     :src="imageSrc" @crop="sendCropData"
                     alt="Source Image">
        </vue-cropper>
    </div>
 
    <el-upload class="avatar-uploader mt-5" v-if="!imageSrc"  :show-file-list="false" :auto-upload="false" :on-change="imageSelected" drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
    </el-upload>
    <el-upload class="upload-button mt-5" :show-file-list="false" :auto-upload="false" :on-change="imageSelected" v-if="showUploadButton">
        <el-button type="warning" size="small" icon="el-icon-camera-solid" >{{imageSrc?'CHANGE':'UPLOAD IMAGE'}}</el-button>
    </el-upload>
</template>

<script>
    export default {
        name: 'ImageUploader',
        components: {},
        props: {
            image: String,
            showUploadButton: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                imageSrc:'',
                replaceImage: () => {
                    if (this.$refs.cropper!=null)
                        this.$refs.cropper.replace(this.imageSrc);
                }
            }
        },
        methods: {
            imageSelected(file) {                
                this.imageSrc = URL.createObjectURL(file.raw);
                this.replaceImage();
                this.$emit('image-selected', file);
            },
            sendCropData() {
                if (this.$refs.cropper)
                    this.$emit('crop', this.$refs.cropper.getData(true));
            }
        },
        mounted() {
            this.imageSrc = this.image;
        }
    }
    </script>
<style>
    .avatar-uploader .el-upload.el-upload--text, .avatar-uploader .el-upload.el-upload--text .el-upload-dragger {
        width: 100%;
    }
    .upload-button {
        position: absolute;
        /*top: 5px;*/
        bottom:-30px;
        left:5px;
        box-shadow: 1px 1px 5px #555;
    }
        .upload-button button {
          
        }
    .image-container, .image-container >div{
        max-width: 100% !important;
        height: 100% !important;
    }
    .image-container > div > img {
            width: 100% !important;
            height: 100% !important;
            object-fit:cover;
     }
    .cropper-container.cropper-bg {
        width: 100% !important;
        height: 100% !important;
    }
    /*.image-container img {
        width: 100% !important;
        height: 100% !important;
        object-fit:cover;
    }*/
</style>